import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentStateAC } from "../store/reducers/app";
import { ReactToPrint } from "react-to-print";

const Queue = () => {
  let services = useSelector((state) => state.app.pickedService);

  const dispatch = useDispatch();

  console.log("dat number", services );

  let date = new Date();

  let dt = date.getDate();
  let mn = date.getMonth() + 1;
  let yr = date.getFullYear();

  let hour = date.getHours();
  let minutes = date.getMinutes();
 

  return (
    <>
      <div
        style={{ gridTemplateRows: "auto 1fr" }}
        className="min-h-screen bg-slate-50 grid grid-cols-1"
        ref={(el) => (services = el)}
      >
        <header
          style={{ borderBottom: "5px solid #FFCF70BF" }}
          className="bg-white flex items-center py-4 px-8"
        >
          <h1 className="font-bold text-3xl">Филиал №1</h1>
        </header>
        <main className="flex justify-center">
          <div>
            <div className="flex justify-center">
              <div>
                <h1 className="text-center my-7 text-3xl">
                Номер очереди 
                </h1>
                <div className="w-[350px] h-[300px] rounded-xl bg-white">
                  <h1 className="pt-5 mb-5 text-3xl text-center font-bold">
                  {services?.response?.number}
                  </h1>
                  <div>
                    <div
                      style={{ borderBottom: "2px solid #eee" }}
                      className="mb-3 pb-1 px-6 flex justify-between "
                    >
                      <b>Дата и время</b>
                      <p>
                        {dt}-{mn < 10 ? "0" + mn : mn}-{yr} {hour}:{minutes}
                      </p>
                    </div>
                    <div
                      style={{ borderBottom: "2px solid #eee" }}
                      className="mb-3 pb-1 px-6 flex justify-between "
                    >
                      <b>Тип клиента</b>
                      <p> {services?.response?.service?.name}</p>
                      {console.log("ServiceS",services)}
                    </div>
                    <div
                      style={{ borderBottom: "2px solid #eee" }}
                      className="mb-3 pb-1 px-6 flex justify-between "
                    >
                      <b>Тип продукта</b>
                      <p> {services?.response?.service?.name}</p>
                    </div>
                    <div
                      style={{ borderBottom: "2px solid #eee" }}
                      className="mb-3 pb-1 px-6 flex justify-between "
                    >
                      <b>Филиал</b>
                      <p>№001</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10 flex justify-center">
              <button className="border-0 w-[250px] bg-amber-500 text-xl rounded-xl text-white py-2 cursor-pointer">
                Отправить СМС
              </button>
              <button
                style={{ border: "1px solid #FFCF70BF" }}
                className="border-0 w-[250px] bg-white text-xl rounded-xl py-2 cursor-pointer ml-4"
              >
                <ReactToPrint
                  trigger={() => {
                    return <button>Распечатать</button>;
                  }}
                  content={() => services}
                  documentTitle="new doc"
                  pageStyle="print"
                />
              </button>
              <button
                style={{ border: "1px solid #FFCF70BF" }}
                className="border-0 w-[250px] bg-red-400 text-xl rounded-xl py-2 cursor-pointer ml-4"
                onClick={() => dispatch(currentStateAC("direction"))}
              >
                Главная
              </button>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Queue;
