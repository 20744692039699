import { createSlice } from '@reduxjs/toolkit';

const app = createSlice({
  name: 'app',
  initialState: {
    currentState: 'login',
    clientType: '',
    categories: [],
    pickedService: {},
    isSpin: false,
    isAuth: false,
    user: [],
    services: []
  },
  reducers: {
    currentStateAC(state, action) {
      state.currentState = action.payload
    },
    clientTypeAC(state, action) {
      state.clientType = action.payload
    },
    isAuthAC(state, action) {
      state.isAuth = action.payload
    },
    categoriesAC(state, action) {
      state.categories = action.payload
    },
    servicesAC(state, action) {
      state.services = action.payload
    },
    pickedServiceAC(state, action) {
      state.pickedService = action.payload
    },
    isSpinAC(state, action) {
      state.isSpin = action.payload;
    },
    userAC(state, action) {
      state.user = action.payload
    },
  }

});

export const { currentStateAC, clientTypeAC, isAuthAC, categoriesAC, servicesAC, pickedServiceAC, userAC, isSpinAC } = app.actions;
export default app.reducer;
