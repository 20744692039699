import axios from 'axios';

const $host = axios.create({
  baseURL: 'http://api.teztar.ru/',
  // headers: {
  //   'Content-Type': 'application/json',
  //   Accept: 'application/json',
  // },
});

$host.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('at')}`;
  return config;
});

export default $host;