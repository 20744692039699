import React, { useState } from "react";
import clearIcon from "../assets/images/clear.png";
import { getTicketApi } from "../http/app";
import { useDispatch, useSelector } from "react-redux";
import { currentStateAC, servicesAC } from "../store/reducers/app";

const Numbers = () => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const pickedService = useSelector((state) => state.app.pickedService);

  const numbersHandle = (e) => {
    if (phoneNumber.length <= 8) {
      setPhoneNumber(phoneNumber + e.target.textContent);
    }
  };
  const clearNumberHandle = () => {
    setPhoneNumber(phoneNumber.slice(0, -1));
  };
  const getTicket = () => {
    const query = {
      phone: ((+992) + phoneNumber),
      service_id: `${pickedService.id}`,
      branch_id: 1,
    };
    if (phoneNumber.length < 9) {
      alert("введите корректний номер");
    } else {
      dispatch(getTicketApi(query));
      dispatch(currentStateAC("queue"));
    }
  };

  return (
    <div
      style={{ gridTemplateRows: "auto 1fr" }}
      className="min-h-screen bg-slate-50 grid grid-cols-1"
    >
      <header
        style={{ borderBottom: "5px solid #FFCF70BF" }}
        className="bg-white flex items-center py-4 px-8"
      >
        <h1 className="font-bold text-3xl">Филиал №1</h1>
      </header>
      <main className="flex justify-center">
        <div>
          <h1 className="text-center my-7 text-2xl">Введите номер телефона</h1>
          <div
            style={{ border: "1px solid #F2994A" }}
            className="flex justify-between items-center w-[450px] py-3 px-5 rounded-xl bg-white"
          >
            <span className="text-3xl">+992</span>
            <input
              maxLength={9}
              value={phoneNumber}
              type="text"
              style={{ width: 220 }}
              className="text-3xl focus:outline-0 border-0"
              placeholder="XXX-XX-XX-XX"
            />
            <img
              onClick={clearNumberHandle}
              className="w-[32px] cursor-pointer"
              src={clearIcon}
              alt=""
            />
          </div>
          <div className="flex justify-center">
            <div className="mt-5 grid grid-cols-3 gap-8">
              <div
                onClick={numbersHandle}
                style={{ borderBottom: "2px solid #F2994A" }}
                className="number w-[80px] h-[70px] rounded-full flex justify-center items-center text-3xl font-bold bg-white cursor-pointer"
              >
                1
              </div>
              <div
                onClick={numbersHandle}
                style={{ borderBottom: "2px solid #F2994A" }}
                className="number w-[80px] h-[70px] rounded-full flex justify-center items-center text-3xl font-bold bg-white cursor-pointer"
              >
                2
              </div>
              <div
                onClick={numbersHandle}
                style={{ borderBottom: "2px solid #F2994A" }}
                className="number w-[80px] h-[70px] rounded-full flex justify-center items-center text-3xl font-bold bg-white cursor-pointer"
              >
                3
              </div>
              <div
                onClick={numbersHandle}
                style={{ borderBottom: "2px solid #F2994A" }}
                className="number w-[80px] h-[70px] rounded-full flex justify-center items-center text-3xl font-bold bg-white cursor-pointer"
              >
                4
              </div>
              <div
                onClick={numbersHandle}
                style={{ borderBottom: "2px solid #F2994A" }}
                className="number w-[80px] h-[70px] rounded-full flex justify-center items-center text-3xl font-bold bg-white cursor-pointer"
              >
                5
              </div>
              <div
                onClick={numbersHandle}
                style={{ borderBottom: "2px solid #F2994A" }}
                className="number w-[80px] h-[70px] rounded-full flex justify-center items-center text-3xl font-bold bg-white cursor-pointer"
              >
                6
              </div>
              <div
                onClick={numbersHandle}
                style={{ borderBottom: "2px solid #F2994A" }}
                className="number w-[80px] h-[70px] rounded-full flex justify-center items-center text-3xl font-bold bg-white cursor-pointer"
              >
                7
              </div>
              <div
                onClick={numbersHandle}
                style={{ borderBottom: "2px solid #F2994A" }}
                className="number w-[80px] h-[70px] rounded-full flex justify-center items-center text-3xl font-bold bg-white cursor-pointer"
              >
                8
              </div>
              <div
                onClick={numbersHandle}
                style={{ borderBottom: "2px solid #F2994A" }}
                className="number w-[80px] h-[70px] rounded-full flex justify-center items-center text-3xl font-bold bg-white cursor-pointer"
              >
                9
              </div>
            </div>
          </div>
          <div className="mt-5 flex justify-center cursor-pointer active:min-h-[70px]">
            <div
              onClick={numbersHandle}
              style={{ borderBottom: "4px solid #F2994A" }}
              className="number w-[80px] h-[70px] rounded-full flex justify-center items-center text-3xl font-bold bg-white cursor-pointer"
            >
              0
            </div>
          </div>
          <div className="mt-7 flex justify-between">
            <button
              className="backBtn w-1/2 py-3 rounded text-lg text-white"
              style={{ width: 215 }}
              onClick={() => dispatch(currentStateAC("services"))}
            >
              Назад
            </button>
            <button
              className="nextBtn w-1/2 py-3 rounded text-lg text-white"
              style={{ width: 215 }}
              onClick={getTicket}
            >
              Вперед
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Numbers;
