import { useSelector, useDispatch } from 'react-redux';
import Direction from './components/Direction';
import Numbers from './components/Numbers';
import Queue from './components/Queue'
import Services from './components/Services'
import { currentStateAC } from './store/reducers/app'
import { useEffect } from 'react';
import Login from './Auth/Login.jsx'

function App() {
  const currentState = useSelector(state => state.app.currentState)
  const dispatch = useDispatch()
  console.log(currentState);

  useEffect(() => {
    const token = localStorage.getItem('at');
    if (token) {
      dispatch(currentStateAC('direction'))
    }
    // else {
    // dispatch(currentStateAC('auth'))
    // }
  }, []);

  return (
    <div className="App">
      {currentState === 'direction' ? <Direction /> : null}
      {currentState === 'numbers' ? <Numbers /> : null}
      {currentState === 'queue' ? <Queue /> : null}
      {currentState === 'services' ? <Services /> : null}
      {currentState === 'login' ? <Login /> : null}
      {/* <Main /> */}
      {/* <Direction /> */}
      {/* <Numbers /> */}
      {/* <Queue /> */}
      {/* <Services/> */}
      {/* {<Login />} */}
    </div>
  );
}

export default App;
