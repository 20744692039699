import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { receptionApi } from "../http/app";
import { currentStateAC, servicesAC } from "../store/reducers/app";

const Direction = () => {
  const dispatch = useDispatch();
  let categories = useSelector((state) => state.app.categories);
  console.log('cat', categories);

  const pickCategory = (item) => {
    dispatch(servicesAC(item.services));
    dispatch(currentStateAC("services"));
  };

  useEffect(() => {
    dispatch(receptionApi());
  }, []);

  return (
    <div
      style={{ gridTemplateRows: "auto 1fr" }}
      className="min-h-screen bg-slate-50 grid grid-cols-1"
    >
      <header
        style={{ borderBottom: "5px solid #FFCF70BF" }}
        className="bg-white flex items-center py-4 px-8"
      >
        <h1 className="font-bold text-3xl">Филиал №1</h1>
      </header>
      <main>
        <h1 className="text-center my-7 text-3xl">Выберите направление</h1>
        <div className="grid grid-cols-2 gap-10 px-10 ">
          {categories.map((item) => (
            <div key={item.id}
              style={{ border: "1px solid #FFCF70BF" }}
              className="py-8 rounded-xl bg-white text-center cursor-pointer text-xl font-bold hover:bg-lime-400"
              onClick={() => pickCategory(item)}
            >
              {item.name}
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default Direction;
