import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { currentStateAC, pickedServiceAC } from "../store/reducers/app";

const Services = () => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.app.services);

  const pickService = (item) => {
    console.log('item', item);
    dispatch(pickedServiceAC(item));
    dispatch(currentStateAC("numbers"));
  };

  return (
    <div
      style={{ gridTemplateRows: "auto 1fr" }}
      className="min-h-screen bg-slate-50 grid grid-cols-1"
    >
      <header
        style={{ borderBottom: "5px solid #FFCF70BF" }}
        className="bg-white flex items-center py-4 px-8"
      >
        <h1 className="font-bold text-3xl">Филиал №1</h1>
      </header>
      <main>
        <h1 className="text-center my-7 text-3xl">Выберите услугу</h1>
        <div className="px-10 grid grid-cols-3 grid-rows-2 justify-center gap-10">
          {services?.map((item) => (
            <div
              style={{ border: "1px solid #FFCF70BF" }}
              className="py-8 rounded-xl bg-white text-center cursor-pointer text-xl font-bold hover:bg-lime-400"
              onClick={() => pickService(item)}
            >
              {item.name}
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <button
            onClick={() => dispatch(currentStateAC("direction"))}
            className="backBtn w-1/2 py-3 rounded text-lg text-white"
            style={{ width: 215 }}
          >
            назад
          </button>
        </div>
      </main>
    </div>
  );
};

export default Services;
