import $host from './index'
import { categoriesAC, currentStateAC, isAuthAC, pickedServiceAC } from '../store/reducers/app'

const loginApi = (query) => async (dispatch) => {
  try {
    const { data } = await $host.post('login', query)
    if (data.meta.success) {
      localStorage.setItem('at', data.response.token);
      dispatch(isAuthAC(true))
    }
    else {
      dispatch(currentStateAC('auth'))
      alert('error', data.meta.message)
    }
  } catch (e) {
    alert('Неправильный логин или пароль')
  }
}

const receptionApi = () => async (dispatch,) => {
  try {
    let token = localStorage.getItem('at');
    const { data } = await $host.get('service-categories', {
      headers: { Authorization: `Bearer ${token}` }
    })
    console.log('data is', data);
    dispatch(categoriesAC(data.response))

  } catch (e) {
    // alert('Ошибка, Что то пошло не так!')
  }
}

const getTicketApi = (query) => async (dispatch) => {
  console.log("Query",query);
  let token = localStorage.getItem('at');
  // let opt={
  //   headers: {
  //     'Accept': 'application/json, text/plain, */*',
  //     'Content-Type': 'application/json',
  //     'Authorization':`Bearer ${token}`
  //   },
  //   body: JSON.stringify(query)
  // }

  try {
    const res = await fetch("http://api.teztar.ru/queues?all", {
      method: "POST",
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(query)
    })
    let data = await res.json();
    console.log(data);
    dispatch(pickedServiceAC(data))

  } catch (e) {
    console.error(e)
    console.log(e);
  }
}

// const getTicket=(query)=>async(dispatch)=>{
//   try {
//     let token = localStorage.getItem('at');
//     const { data } = await $host.get('tickets', {
//       headers: { Authorization: `Bearer ${token}` }
//     })
//     console.log('data tickets ', data.response);
//     dispatch(pickedServiceAC(data.response))

//   } catch (e) {
//     // alert('Ошибка, Что то пошло не так!')
//   }
// }

export { receptionApi, getTicketApi, loginApi }
