import { Button, Checkbox, Form, Input, Typography } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import loginApi from '../http/auth';
import { currentStateAC } from '../store/reducers/app';

const { Title } = Typography;
function Login() {
    const dispatch = useDispatch();

    const onFinish = async value => {
        const query = {
            email: value.email,
            password: value.password,
        };

        dispatch(loginApi(query));
        dispatch(currentStateAC('direction'))
    };

    return (
        <div className='h-[100vh] flex items-center'>
            <Form style={{ width: '35%', margin: 'auto', boxShadow: '0px 0px 10px lightgray', padding: '15px', borderRadius: '7px' }} name='login' initialValues={{ remember: true }} onFinish={onFinish} autoComplete='off'>
                <Title style={{ marginBottom: '30px', textAlign: 'center' }} level={2}>
                    Вход
                </Title>

                <Form.Item name='email' rules={[{ required: true, message: 'Обязательное поле!' }]}>
                    <Input placeholder='Email' />
                </Form.Item>

                <Form.Item
                    name='password'
                    rules={[
                        { required: true, message: 'Обязательное поле!' },
                        { min: 4, message: 'Поле должно быть не менее 8 символов!' },
                    ]}
                >
                    <Input.Password placeholder='Пароль' />
                </Form.Item>

                <Form.Item name='remember' valuePropName='checked'>
                    <Checkbox>Запомнить меня</Checkbox>
                </Form.Item>

                <Form.Item>
                    <div className='flex justify-end'>
                        <Button type='primary' style={{ backgroundColor: '#129AE8' }} htmlType='submit' >
                            Войти в систему
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Login;
